import {
  signalStore,
  withState,
  withComputed,
  withMethods,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { PlaylistService } from '@desquare/services';
import { Signal, computed, inject, signal } from '@angular/core';
import { distinctUntilChanged, pipe, switchMap } from 'rxjs';
import { GraphQLFormattedError } from 'graphql';
import { Playlist } from '@designage/gql';
import { PlaylistAssetItem } from '@desquare/types';

export interface IPlaylistsSignal {
  playlists: Playlist[];
  channelPlaylists: Playlist[];
  loading: boolean;
  errors: GraphQLFormattedError<Record<string, any>>[] | null;
}

const initialState: IPlaylistsSignal = {
  playlists: [],
  channelPlaylists: [],
  loading: true,
  errors: null,
};

export const PlaylistsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withMethods((store, playlistService = inject(PlaylistService)) => ({
    getPlaylistsFromApi: rxMethod<void>(
      pipe(
        distinctUntilChanged(),
        switchMap(() => {
          return playlistService.getProfilePlaylistsFromApi$().pipe(
            tapResponse({
              next: ({ playlists, errors, loading }) => {
                patchState(store, {
                  playlists,
                  loading,
                  errors: errors ? [...errors] : [],
                });
              },
              error: console.error,
              finalize: () => patchState(store, { loading: false }),
            }),
          );
        }),
      ),
    ),
    getPlaylist: (playlistId: string): Signal<Playlist | undefined> => {
      const playlist = signal<Playlist | undefined>(
        store.playlists().find((x) => x.id === playlistId),
      );
      return playlist;
    },
    updatePlaylist: (playlist: Playlist) => {
      patchState(store, {
        playlists: store.playlists().map((x) => {
          if (x.id === playlist.id) {
            return { ...x, ...playlist };
          } else {
            return x;
          }
        }),
      });
    },
    addChannel: (playlist: Playlist) => {
      patchState(store, {
        playlists: [...store.playlists(), playlist],
      });
    },
    removeChannel: (playlistId: string) => {
      patchState(store, {
        playlists: store.playlists().filter((x) => x.id !== playlistId),
      });
    },
  })),
  withHooks((store) => ({
    onInit() {
      // Load all playlists when the store is initialized
      store.getPlaylistsFromApi();
    },
  })),
  withComputed((state) => ({})),
);

export interface IPlaylistPreview {
  content: PlaylistAssetItem | null;
  showContent: boolean;
}

export const PlaylistPreviewStore = signalStore(
  { providedIn: 'root' },
  withState<IPlaylistPreview>({ content: null, showContent: true }),
  withMethods((store) => ({
    setContentInPreview: (content: PlaylistAssetItem) => {
      patchState(store, { content });
    },
    clearContentInPreview: () => {
      patchState(store, { content: null });
    },
    setContentStatus: (showContent: boolean) => {
      patchState(store, { showContent });
    },
  })),
  withHooks((store) => ({})),
  withComputed((store) => ({
    currentContent: computed(() => {
      return store.showContent() ? store.content() : null;
    }),
  })),
);
